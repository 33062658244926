import { Box, Flex, Link, Text } from '@chakra-ui/react'
import Markdown, { RuleType } from 'markdown-to-jsx'
import React, { ReactElement } from 'react'
import { QuoteBox } from './QuoteBox'

type Props = {
  title: string
  markdown: string
  horizontalAlignment: 'Left' | 'Center' | 'Right'
  verticalAlignment: 'Top' | 'Center' | 'Bottom'
}

const ALLOWED_RULES = new Set([
  RuleType.blockQuote,
  RuleType.breakLine,
  RuleType.newlineCoalescer,
  RuleType.orderedList,
  RuleType.paragraph,
  RuleType.text,
  RuleType.textBolded,
  RuleType.textEmphasized,
  RuleType.textStrikethroughed,
  RuleType.unorderedList,
  RuleType.link,
])

const horizontalAlignmentToFlexbox = (val: 'Left' | 'Center' | 'Right') => {
  switch (val) {
    case 'Right':
      return 'flex-end'
    case 'Center':
      return 'center'
    default:
      return 'flex-start'
  }
}

const horizontalAlignmentToTextAlign = (val: 'Left' | 'Center' | 'Right') => {
  switch (val) {
    case 'Right':
      return 'right'
    case 'Center':
      return 'center'
    default:
      return 'left'
  }
}

const verticalAlignmentToCss = (val: 'Top' | 'Center' | 'Bottom') => {
  switch (val) {
    case 'Top':
      return 'flex-start'
    case 'Center':
      return 'center'
    default:
      return 'flex-end'
  }
}

const ScrollBox: React.FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="inherit"
      width="100%"
      overflowY="auto"
    >
      {children}
    </Flex>
  )
}
const MarkdownRenderer: React.FC<Props> = ({
  horizontalAlignment,
  verticalAlignment,
  title,
  markdown,
}) => {
  return (
    <Flex
      height="100%"
      direction="column"
      alignItems={horizontalAlignmentToFlexbox(horizontalAlignment)}
      justifyContent={verticalAlignmentToCss(verticalAlignment)}
      fontSize="md"
      textAlign={horizontalAlignmentToTextAlign(horizontalAlignment)}
    >
      <Text fontSize="xl" fontWeight="medium">
        {title}
      </Text>
      <Markdown
        options={{
          wrapper: ScrollBox,
          forceWrapper: true,
          disableParsingRawHTML: true,
          overrides: {
            a: {
              component: Link,
              props: { as: 'a', color: 'primary.500' },
            },
            ul: { component: Box, props: { as: 'ul', marginLeft: '6' } },
            ol: { component: Box, props: { as: 'ol', marginLeft: '6' } },
            blockquote: {
              component: QuoteBox,
              props: {},
            },
            text: {
              component: Text,
              props: { fontSize: 'md', textAlign: 'right' },
            },
          },
          renderRule: (next, node) => {
            //We only allow a subset of types
            if (ALLOWED_RULES.has(node.type)) {
              return next()
            }

            //Typescript fix
            //Not all types have a text, so we return an empty string if they dont
            return (node as unknown as { text?: string | undefined }).text || ''
          },
        }}
      >
        {markdown}
      </Markdown>
    </Flex>
  )
}

export default MarkdownRenderer
