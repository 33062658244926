import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  Text,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ReactNode } from 'react'
import { QuoteBox } from './QuoteBox'

type Props = { children: ReactNode }

const CodeBox: React.FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Text bg="gray.100" p="2">
      {children}
    </Text>
  )
}

const MarkdownTooltip: React.FC<Props> = ({ children }) => {
  return (
    <Popover placement="top">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Trans>Markdown help</Trans>
        </PopoverHeader>
        <PopoverBody>
          <SimpleGrid columns={2} spacing={2}>
            <Text fontWeight="bold" p="2">
              <Trans>Bold</Trans>
            </Text>
            <CodeBox>
              <Trans>**bold text**</Trans>
            </CodeBox>

            <Text fontStyle="italic" p="2">
              <Trans>Italic</Trans>
            </Text>
            <CodeBox>
              <Trans>*italic*</Trans>
            </CodeBox>

            <Text textDecoration="line-through" p="2">
              <Trans>Strikethrough</Trans>
            </Text>
            <CodeBox>
              <Trans>~~Strikethrough~~</Trans>
            </CodeBox>

            <Text p="2">
              <Trans>1. Numbered list</Trans>
            </Text>
            <CodeBox>
              <Trans>1. Numbered list</Trans>
            </CodeBox>

            <Text p="2">
              <Trans>- Bullet list</Trans>
            </Text>
            <CodeBox>
              <Trans>- Bullet list</Trans>
            </CodeBox>

            <QuoteBox m="0">
              <Trans>Quote</Trans>
            </QuoteBox>
            <CodeBox>{t`> Quote`}</CodeBox>

            <Text
              p="2"
              color="primary.500"
              _hover={{ textDecoration: 'underline' }}
            >
              <Trans>Link</Trans>
            </Text>
            <CodeBox>
              <Trans>[description](url)</Trans>
            </CodeBox>
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default MarkdownTooltip
